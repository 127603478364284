<template>
    <Loading :isLoading="isLoading" />
    <div class="bg-white m-0 px-5 md:px-3 md:m-5 ">
        <div class="grid grid-cols-1 md:grid-cols-2 px-0 md:px-0 lg:px-20 mt-10">

            <div class="w-full md:w-4/5 md:mx-auto mt-5">
                <p class="text-3xl font-bold roboto-regular mb-4">{{ questionTitle }}</p>
                <QuotationHeightAndWeightComponent ref="ComponentsPersonalDataRef" :view_class_view="view_class_view" :validations="rulesValidations" v-on:recover_password="recover_password($event)" />

                <div class="w-full flex justify-center">
                   <input type="button" id="button_next" :disabled="!isActive" :class="{'button-disabled cursor-default': !isActive, 'bg-mustardDark cursor-pointer': isActive}" class="w-full text-white px-5 py-2 mx-auto my-4 h-14 md:h-14  rounded text-center font-medium text-xl" @click="next()" value="Continuar">        
                </div>
            </div>
            <div class="w-full bg-white hidden md:block md:py-14">
                <img src="@/assets/Cuenta/vidaSimple/personal_info.svg" :alt="questionTitle" class="mx-auto" >
            </div>
        </div> 
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { helpers, requiredIf} from '@vuelidate/validators';

import Loading from '@/components/Loading/VueLoading.vue';
import { useRouter, useRoute } from 'vue-router';
import { getLocalStorage, createLocalStorage } from '@/helpers/storage.js';

export default {
    name: "PersonalData",
    setup(){
        const router = useRouter();
        const route = useRoute();
        const isLoading = ref(true);
        const ComponentsPersonalDataRef = ref(null);
        const validations = ref({});
        const category = ref("");

        let questionTitle = ref('Datos personales');
        let imageCategory = ref('personal_info.svg');
        let isActive = ref(false);

        onMounted(async()=>{
            category.value = getCategory();

            isLoading.value = false;
            isActive.value = true;
        });

        const getCategory = () => {
            return route.params.product;
        }

        const saveData = (values, category) => {
            
            const nameStorage = `quote-${category}`;
            
            let quote = getLocalStorage(nameStorage);

            quote.height = parseInt(values.height.replace(".", ""));
            quote.weight = parseInt(values.weight);
            
            createLocalStorage(nameStorage, quote)
        }

        let view_class_view = {
            class_inputs:{
                valid:"border-blue-600",
                no_valid:"border-red-600",
                default:"w-full py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none "
            },
            div_container_error:{
                container:"my-2",
                text_error:"text-red-600",
            },
            div_container:"grid grid-cols-1 sm:gap-10",
        };

        let rulesValidations = {
            height: {
                required: helpers.withMessage('El campo es requerido', requiredIf(true)),
                minLenght: helpers.withMessage('La altura mínima es 1.20 mts', function(value, index){
                    let height = Number(index.height);
                    if(height > 0){
                        return true;
                    }
                    return false;
                }),
                numberFormat: helpers.withMessage('Únicamente se aceptan valores numéricos.', function (data, index) {
                    return new RegExp(/^[0-9]+([.][0-9]{1,2})?$/).test(index.height.toString());
                }),
                valueTwoNumber:helpers.withMessage("Después del punto solo se admiten 2 decimales.",function(data,index) {
                    if(index.height!=""){
                        return new RegExp(/^[0-9]+([.][0-9]{1,2})?$/).test(index.height.toString())
                    }
                    else return true;
                }),
                maxHeigth:helpers.withMessage(`La altura maxima es 2.52 mts.`, function(data,index){
                
                    if(index.height > 0 && index.height > 2.52){
                        return false
                    }
                    if(index.height == 0){
                        return false
                    } else {
                        return true
                    }
                })
            },
            weight: {
                required: helpers.withMessage('El campo es requerido', requiredIf(true)),
                valueTwoNumber:helpers.withMessage("Después del punto solo se admiten 2 decimales.",function(data,index) {
                    if(index.weight!=""){
                        return new RegExp(/^[0-9]+([.][0-9]{1,2})?$/).test(index.weight.toString())
                    }
                    else return true;
                }),
                maximum_weight: helpers.withMessage(`El peso maximo son 200 Kg.`, function(data,index){
                    if(index.weight!=""){
                        return parseFloat(index.weight) <= 200
                    }
                    else{
                        return true
                    }
                    
                }),
                minimun_weight: helpers.withMessage(`El peso mínimo son 40 Kg.`, function(data,index){
                if(index.weight!=0){
                    return parseFloat(index.weight) >= 40
                }
                if(index.weight==0){
                    return false
                }else{
                    return true
                }                
                })
            }
        }
            
        const next = async ()   => {
            const ComponentsPersonalDataRefs = ComponentsPersonalDataRef.value.execute_validation();
            if(!ComponentsPersonalDataRefs) return false ;
            createLocalStorage('quote-personal-data', ComponentsPersonalDataRefs);
            saveData(ComponentsPersonalDataRefs, category.value)
            router.push('contacto')
        }
        
        return {
            next,
            isActive,
            isLoading,
            validations,
            questionTitle,
            imageCategory,
            rulesValidations,
            view_class_view,
            ComponentsPersonalDataRef
        }
    },
    components:{
        Loading
    }

}
</script>
<style>
.question-container > div > div {
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px;
}

.question-container > div > div > button{
    border-radius:5px;
    width:49%;
}

.question-container>div>label, .question-container>div>input, .question-container>div>button {
     font-family: "roboto-regular";
}

.question-container > div > div > button.active {
    background-color: #810042;
    color: white;
    border-color: #810042;
}

.question-container > div > div > button {
    box-sizing: border-box;
    background: #D9D9D9;
    color: white;
    border-radius: 5px;
    font-size: 18px;
    margin-inline: 1px;
}

.question-container>div>label {
    font-size: 18px;
    line-height: 30px;
    color: #485462;
}

.card-shadow {
  box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -webkit-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -moz-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
}

.content_textarea_0 {
    margin-top: 20px;
}

.content_date_3, .content_date_4 {
    margin-top: 9px;
}

.vpd-input-group input {
    flex-grow: 1;
    border: 2px solid #e5e7eb;
    border-right: 2px solid #e5e7eb;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    line-height: 30px;
    padding: 0 10px;
    height: 43px;
}

.content_textarea_6 {
    display:flex;
}

.content_checkbox_1 > div {
    width:100%!important;
}


@media only screen and (min-width: 600px) {
    .content_date_3, .content_date_4 {
        display: block;
    }
}

@media only screen and (max-width: 600px) {
    .content_date_3, .content_date_4 {
        display: flex;
    }
}

#input_rfc_contracting_data_1,
#input_curp_contracting_data_2{
    text-transform: uppercase;
}
</style>
<style scoped>
.button-disabled {
    background-color: #EDD191;
}



@media only screen and (min-width: 600px) {
    .detail-container {
        background-color: #FAFAFA;
    }
    .content_date_3, .content_date_4 {
        display: block;
    }
}
@media only screen and (max-width: 600px) {
    .bg-container {
        background-color: #FCFCFC;
    } 
    .content_date_3, .content_date_4 {
        display: flex;
    }
}
button {
    font-size: 19px!important;
}

</style>